import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import {
  actions,
  REQUEST_GET_GRAPHIC_PACKAGES,
  REQUEST_DEPRECATE_GRAPHIC_PACKAGE,
  GraphicPackagesResponseType,
} from '../reducers/graphicPackage';
import { NOTIFY } from '../reducers/notification';

type GraphicPackageApiResponse = void | { data: GraphicPackagesResponseType; status: number };

function* getGraphicPackages(
  action: PayloadAction<{ deprecated: boolean }>
): Generator<PutEffect | CallEffect<GraphicPackageApiResponse>, void, GraphicPackageApiResponse> {
  try {
    const { deprecated } = action.payload;

    const endpoint = deprecated ? `/graphicpackages?filter[deprecated]=true` : `/graphicpackages`;
    const response = yield call(api.get, endpoint);
    const apiResponse = response as GraphicPackageApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetGraphicPackages(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetGraphicPackages((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

function* deprecateGraphicPackage(
  action: PayloadAction<{ id: string; deprecated: boolean }>
): Generator<PutEffect | CallEffect<GraphicPackageApiResponse>, void, GraphicPackageApiResponse> {
  try {
    const { id, deprecated } = action.payload;
    const response = yield call(api.patch, `/graphicpackages/${id}/deprecate`, { deprecated });
    const apiResponse = response as GraphicPackageApiResponse;
    if (apiResponse?.data) {
      yield put({ type: REQUEST_GET_GRAPHIC_PACKAGES, payload: { deprecated: true } });
      yield put(actions.receiveDeprecateGraphicPackage());
      yield put({
        type: NOTIFY,
        payload: {
          variant: 'info',
          title: deprecated
            ? 'Your graphic package has been deprecated'
            : 'Your graphic package is in use',
        },
      });
    }
  } catch (error) {
    yield put(
      actions.failedDeprecateGraphicPackage((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_GRAPHIC_PACKAGES, getGraphicPackages);
  yield takeLatest(REQUEST_DEPRECATE_GRAPHIC_PACKAGE, deprecateGraphicPackage);
}
